import Axios from "axios";

import {
  TCreateLotFormValues,
  TCreateOnlineLotFormValues,
} from "@components/forms/CreateLotForm";
import {
  TUpdateLotFormValues,
  TUpdateOnlineLotFormValues,
} from "@components/forms/UpdateLotForm";
import { IAsset, ILot, ILotBidder, ILotBidders, IMedia, IReport } from "@utils";

export async function create(
  props: TCreateLotFormValues & TCreateOnlineLotFormValues,
  assetsIds: number[]
) {
  const response = await Axios.post<ILot>("/api/lots/", {
    asset_ids: assetsIds,
    ...(props.assetFamily && { asset_family: props.assetFamily }),
    asset_family_detail: {
      ...(props.engine && { engine: props.engine }),
      ...(props.transmission && { transmission: props.transmission }),
      ...(props.driverSide && { driver_side: props.driverSide }),
      ...(props.yearOfMake && { year_of_manufacture: props.yearOfMake }),
      ...(props.mileage && { mileage_km: props.mileage }),
      ...(props.country && { country_geoname_id: +props.country }),
      ...(props.brand && { brand: props.brand }),
      ...(props.model && { model: props.model }),
    },
    custom_clearance: props.customClearance,
    number: props.lot,
    name: props.name,
    ...(props.description && { description: props.description }),
    reserve_price: props.reservePrice.toString(),
    ...(props.startPrice && { start_price: props.startPrice.toString() }),
    ...(props.minStep && { minimal_bid_step: props.minStep.toString() }),
  });
  return response.data;
}

export async function byId(id: number) {
  const response = await Axios.get<ILot>(`/api/lots/${id}/`);
  return response.data;
}

export async function update(
  id: number,
  values: Partial<TUpdateLotFormValues & TUpdateOnlineLotFormValues>
) {
  const response = await Axios.patch(`/api/lots/${id}/`, {
    asset_family: values.assetFamily,
    asset_family_detail: {
      ...(values.engine && { engine: values.engine }),
      ...(values.transmission && { transmission: values.transmission }),
      ...(values.driverSide && { driver_side: values.driverSide }),
      ...(values.yearOfMake && { year_of_manufacture: values.yearOfMake }),
      ...(values.mileage && { mileage_km: values.mileage }),
      ...(values.country && { country_geoname_id: +values.country }),
      ...(values.brand && { brand: values.brand }),
      ...(values.model && { model: values.model }),
    },
    custom_clearance: values?.customClearance,
    number: values.lot,
    name: values.name,
    ...(values.description && { description: values.description }),
    reserve_price: values.reservePrice?.toString(),
    start_price: values?.startPrice?.toString(),
    minimal_bid_step: values?.minStep?.toString(),
  });
  return response.data;
}

export async function assetsById(id: number) {
  const response = await Axios.get<IAsset[]>(`/api/lots/${id}/assets/`);
  return response.data;
}

export async function addAssets(id: number, assetsIds: number[]) {
  const response = await Axios.put<ILot>(`/api/lots/${id}/assets/add/`, {
    asset_ids: assetsIds,
  });
  return response.data;
}

export async function removeAssetsById(lotId: number, assetsIds: number[]) {
  const response = await Axios.put<ILot>(`/api/lots/${lotId}/assets/remove/`, {
    asset_ids: assetsIds,
  });
  return response.data;
}

export async function createMedia(lotId: number, media: IMedia) {
  const response = await Axios.post(`/api/lots/${lotId}/media/`, {
    ...media,
  });
  return response.data;
}

export async function updateMedia(lotId: number, media: IMedia[]) {
  const response = await Axios.put(`/api/lots/${lotId}/media/`, media);
  return response.data;
}

export async function addInspectionReport(lotId: number, data: IReport) {
  const response = await Axios.put(`/api/lots/${lotId}/inspection-file/`, {
    ...data,
  });
  return response.data;
}

export async function getInspectionReport(lotId: number | undefined) {
  const response = await Axios.get(`/api/lots/${lotId}/inspection-file/`);
  return response.data;
}

export async function markBidPaid(lotId: number, bidId: number, paid: boolean) {
  const response = await Axios.patch(`/api/lots/${lotId}/bids/${bidId}/`, {
    paid: !paid,
  });
  return response.data;
}

export async function getLotBids(lotId: number) {
  const response = await Axios.get<ILotBidders>(
    `/api/lots/${lotId}/bids/table/`
  );
  return response.data;
}

export async function changeWinnerBidder(lotId: number, bidId: number) {
  const response = await Axios.patch(
    `/api/lots/${lotId}/bids/${bidId}/winner/`
  );
  return response.data;
}
