import Axios from "axios";
import { saveAs } from "file-saver";
import qs from "qs";

import {
  TCreateAuctionFormValues,
  TUpdateAuctionFormValues,
} from "@components";
import {
  AuctionStatus,
  encodeMultipleFilters,
  IAsset,
  IAuction,
  IAuctionReport,
  IAuctionReportLots,
  IEmailLanguage,
  ILot,
  IPaginatedResponse,
  IPaidBid,
  IUploadLots,
  Nullable,
  PresignedUrl,
  removeEmptyValues,
} from "@utils";
import { encodeOptionValues } from "./dto/encodeAuctionValues";

export async function create(values: TCreateAuctionFormValues) {
  removeEmptyValues(values);
  const encodedValues = encodeOptionValues(values);

  const response = await Axios.post("/api/auctions/", encodedValues);
  return response.data;
}

export async function update(id: number, values: TUpdateAuctionFormValues) {
  removeEmptyValues(values);
  const encodedValues = encodeOptionValues(values);

  const response = await Axios.patch(`/api/auctions/${id}/`, encodedValues);
  return response.data;
}

export async function get(page?: number) {
  const response = await Axios.get<IPaginatedResponse<IAuction>>(
    "/api/auctions/",
    {
      params: {
        page,
      },
    }
  );
  return response.data;
}

export interface IPreliveAuction {
  id: number;
  city: string;
  country: string;
  auction_date: string;
}

export async function getPreLive() {
  const response = await Axios.get<IPreliveAuction[]>(
    "/api/auctions/pre-live/"
  );
  return response.data;
}

export async function getOnline() {
  const response = await Axios.get("/api/auctions/online/");
  return response.data;
}

export interface IPreDone {
  id: number;
  city: string;
  country: string;
  auction_date: string;
  auction_end_date: string;
}
export async function getPreDone() {
  const response = await Axios.get<IPreDone[]>("/api/auctions/pre-done/");
  return response.data;
}

export async function byId(id: number) {
  const response = await Axios.get<IAuction>(`/api/auctions/${id}/`);
  return response.data;
}

export async function done(id: number) {
  const response = await Axios.put(`/api/auctions/${id}/done/`);
  return response.data;
}

export async function finalize(id: number) {
  const response = await Axios.put(`/api/auctions/${id}/finalize/`);
  return response.data;
}

export async function changeStatus(id: number, status: AuctionStatus) {
  const response = await Axios.put(
    `/api/auctions/${id}/pre-finalized/${status}/`
  );
  return response.data;
}

export async function assetsById(
  id: number,
  page: number,
  acquisitionDateFrom?: string,
  acquisitionDateTo?: string,
  approvalDateFrom?: string,
  approvalDateTo?: string,
  brand?: string[],
  family?: string[],
  functionalLoc?: string[],
  model?: string[],
  stateOfUse?: string[],
  unassociated?: boolean
) {
  const response = await Axios.get<IPaginatedResponse<IAsset>>(
    `/api/auctions/${id}/assets/`,
    {
      params: {
        page,
        acquisition_date_start: acquisitionDateFrom,
        acquisition_date_end: acquisitionDateTo,
        psb_approval_date_start: approvalDateFrom,
        psb_approval_date_end: approvalDateTo,
        brand: encodeMultipleFilters(brand),
        asset_family: encodeMultipleFilters(family),
        functional_loc: encodeMultipleFilters(functionalLoc),
        model: encodeMultipleFilters(model),
        user_status: encodeMultipleFilters(stateOfUse),
        unassociated,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
  return response.data;
}

export async function lotsById(
  id: number,
  page: number,
  numberFrom?: string,
  numberTo?: string,
  reservePriceFrom?: number,
  reservePriceTo?: number,
  acquisitionValueFrom?: number,
  acquisitionValueTo?: number,
  marketValueFrom?: number,
  marketValueTo?: number
) {
  const response = await Axios.get<IPaginatedResponse<ILot>>(
    `/api/auctions/${id}/lots/`,
    {
      params: {
        page,
        acquisition_value_min: acquisitionValueFrom,
        acquisition_value_max: acquisitionValueTo,
        market_value_min: marketValueFrom,
        market_value_max: marketValueTo,
        number_min: numberFrom,
        number_max: numberTo,
        reserve_price_min: reservePriceFrom,
        reserve_price_max: reservePriceTo,
      },
    }
  );
  return response.data;
}

export async function getTranscribingTemplateById(id: number) {
  const response = await Axios.get<PresignedUrl>(
    `/api/auctions/${id}/bid-template/`
  );
  return response.data;
}

interface IBoSParams {
  empty: string;
  language?: string;
}
export async function getBillOfSale(
  id: number,
  isPrefilled?: boolean,
  language?: string
) {
  const empty = isPrefilled ? "no" : "yes";
  const params: IBoSParams = {
    empty: empty,
  };
  if (language) {
    params.language = language;
  }
  try {
    const response = await Axios.get(`/api/auctions/${id}/bos/`, {
      responseType: "blob",
      params: params,
    });
    const blob = new Blob([response.data], { type: "application/zip" });
    saveAs(blob, "bill_of_sale.zip");
  } catch (error) {
    console.error("Error downloading file", error);
  }
}

export async function getWinnerEmails(id: number) {
  try {
    const response = await Axios.get(
      `/api/auctions/${id}/winners/email/export/`,
      {
        responseType: "blob",
      }
    );
    const blob = new Blob([response.data], { type: "application/pdf" });
    saveAs(blob, "emails.pdf");
  } catch (error) {
    console.error("Error downloading file", error);
  }
}

export async function updateFile(id: number, url: string, key: string) {
  const response = await Axios.put<IAuction>(
    `/api/auctions/${id}/update-file/`,
    {
      url,
      key,
    }
  );
  return response.data;
}

export async function importLots(id: number, data: IUploadLots) {
  const response = await Axios.put(`/api/auctions/${id}/import-lots/`, {
    location_geoname_id: data.location_geoname_id,
    url: data.url,
    key: data.key,
  });
  return response.data;
}

export async function exportLots(id: number) {
  const response = await Axios.get<PresignedUrl>(
    `/api/auctions/${id}/lot-file/`
  );
  return response.data;
}

export async function removeAssets(id: number, assetsIds: number[]) {
  const response = await Axios.put<{}>(`/api/auctions/${id}/assets/remove/`, {
    asset_ids: assetsIds,
  });
  return response.data;
}

export async function reportById(
  id: number
): Promise<Nullable<IAuctionReport>> {
  const response = await Axios.get<IAuctionReport>(
    `/api/auctions/${id}/report/`
  );
  return response.data;
}

export async function exportReportFile(id: number, taskId?: string) {
  try {
    const response = await Axios.get<PresignedUrl>(
      `/api/auctions/${id}/report-file/`,
      {
        params: {
          task_id: taskId,
        },
      }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function generateReportFile(id: number) {
  const response = await Axios.post(`/api/auctions/${id}/report-file/create/`);
  return response.data;
}

export async function reportLotsById(id: number) {
  const response = await Axios.get<IAuctionReportLots>(
    `/api/auctions/${id}/report/lots/`
  );
  return response.data;
}

export async function getEmailLanguages() {
  const response = await Axios.get<Array<IEmailLanguage>>(
    `/api/auctions/languages/`
  );
  return response.data;
}

export async function addAuctionImage(id: number, url: string, key: string) {
  const response = await Axios.put(`/api/auctions/${id}/image-file/`, {
    url,
    key,
  });
  return response.data;
}

export async function getAuctionImage(id: number) {
  const response = await Axios.get(`/api/auctions/${id}/image-file/`);
  return response;
}

export async function sendWinnersEmail(id: number) {
  const response = await Axios.post(`/api/auctions/${id}/winners/email/`);
  return response.status;
}

export async function getAuctionDeposits(id: number, userId: number) {
  const response = await Axios.get(
    `/api/auctions/${id}/deposits/user/${userId}/`
  );
  return response.data;
}

export async function getPaidBids(auctionId: number) {
  try {
    const response = await Axios.get<IPaidBid>(
      `/api/auctions/${auctionId}/lots/paid/`
    );
    return response.data;
  } catch (error) {
    console.log("getPaidBids error", error);
    return Promise.reject(error);
  }
}

export async function sendEmailPickupDetails(id: number, lotIds: number[]) {
  const response = await Axios.post(
    `/api/auctions/${id}/lots/email-pickup-details/`,
    {
      lots: lotIds,
      export: false,
    }
  );
  return response.status;
}
