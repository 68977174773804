import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import { Form as BaseForm, Formik, FormikHelpers } from "formik";
import styled from "styled-components";

import { API } from "@api";
import {
  FileField,
  Icon,
  Loader,
  Modal,
  NumberField,
  SwitchField,
  Tabs,
  TextField,
} from "@components";
import { ButtonGroupField } from "@components/ButtonGroupField";
import { DateField } from "@components/DateField";
import { useI18n } from "@i18n";
import { device } from "@styles/breakpoints";
import {
  Agency,
  AssetFamily,
  AuctionStatus,
  getCityName,
  IAuction,
  ICity,
  ICountry,
  IEmailLanguage,
  OptionalNotNull,
} from "@utils";
import { Button } from "../../Button";
import { Label } from "../../Label";
import { SelectField } from "../../SelectField";
import { TextAreaField } from "../../TextAreaField";
import {
  MAX_CONVERSION_RATE_VALUE,
  MIN_CONVERSION_RATE_VALUE,
  MIN_NON_WFP_LOTS_VALUE,
  MIN_UNHCR_SERVICES_VALUE,
} from "../CreateAuctionForm";
import { AuctioneerFeeByAssetField } from "../CreateAuctionForm/AuctioneerFeeByAssetField";
import { DepositByAssetField } from "../CreateAuctionForm/DepositByAssetField";
import { INITIAL_VALUES } from "./initialValues";
import { validateForm, VALIDATION_SCHEMA } from "./validationSchema";

export type TUpdateAuctionFormValues = typeof INITIAL_VALUES;

type Props = {
  initialValues: IAuction;
  onSubmit: (
    values: TUpdateAuctionFormValues,
    helpers: FormikHelpers<TUpdateAuctionFormValues>
  ) => Promise<void>;
  loading?: boolean;
  onClose?: () => void;
};

const Form = styled(BaseForm)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;

  & > *:not(:last-child) {
    margin-right: 24px;
  }

  @media ${device.tablet} {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  max-width: 300px;
`;

const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;

  div {
    margin-top: 0;
  }
`;

const RowWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LabelBox = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
  width: 200px;

  & ${Label} {
    margin-right: auto;
  }
`;

const TooltipContent = styled.div`
  max-width: 20rem;
`;

export const UpdateAuctionForm: FC<Props> = ({
  loading,
  initialValues,
  onSubmit,
  onClose,
}) => {
  const { t } = useI18n();
  const [cities, setCities] = useState<ICity[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [currencies, setCurrencies] = useState<{ code: string }[]>([]);
  const [emailLanguages, setEmailLanguages] = useState<IEmailLanguage[]>([]);
  const [conversionRateDisabled, setConversionRateDisabled] = useState(
    initialValues.currency.code === "USD"
  );
  const [auctionImage, setAuctionImage] = useState<string>("");
  const [uploadedPhoto, setUploadedPhoto] = useState<string>("");
  const [imageIsLoading, setImageIsLoading] = useState<boolean>(false);
  const [timezones, setTimezones] = useState<Array<any>>([]);
  const [gasStrategies, setGasStrategies] = useState<Array<any>>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    async function getCountries() {
      try {
        const countries = await API.users.countries();
        setCountries(countries);
      } catch (_) {
        setCountries([]);
      }
    }

    async function getCurrencies() {
      try {
        const currencies = await API.utils.currencies();
        setCurrencies(currencies);
      } catch (_) {
        setCurrencies([]);
      }
    }

    async function getEmailLanguages() {
      try {
        const languages = await API.auctions.getEmailLanguages();
        setEmailLanguages(languages);
      } catch (_) {
        setEmailLanguages([]);
      }
    }

    async function getTimezones() {
      try {
        const timezones = await API.utils.getTimezones();
        setTimezones(timezones);
      } catch (_) {
        setTimezones([]);
      }
    }

    const getAuctionImage = async (id: number) => {
      setImageIsLoading(true);
      try {
        const image = await API.auctions.getAuctionImage(id);
        const dataTransfer = new DataTransfer();
        let blob = await fetch(image.data.presigned_url).then((r) => r.blob());
        let metadata = {
          type: "image",
        };
        let file = new File([blob], "AuctionImage", metadata);
        dataTransfer.items.add(file);
        setAuctionImage(image.data.presigned_url);
        setImageIsLoading(false);
      } catch (e) {
        console.log("Get auction image error", e);
        setImageIsLoading(false);
      }
    };

    async function getGasStrategies() {
      try {
        const strategies = await API.utils.getGasStrategies();
        setGasStrategies(strategies.results);
      } catch (_) {
        setGasStrategies([]);
      }
    }

    getCountries();
    getCurrencies();
    getEmailLanguages();
    getTimezones();
    getGasStrategies();
    initialValues.auction_image && getAuctionImage(initialValues.id);
  }, []);

  async function getCitiesByCountry(countryId: number) {
    try {
      const cities = await API.users.citiesByCountry(countryId);
      setCities(cities);
    } catch (_) {
      setCities([]);
    }
  }

  useEffect(() => {
    if (initialValues.location) {
      getCitiesByCountry(initialValues.location.country.geoname_id);
    }
  }, [countries, initialValues]);

  const handleSubmit = useCallback(
    async (values, helpers) => {
      let valuesToUpdate = values;

      if (
        initialValues.status === AuctionStatus.DONE ||
        initialValues.status === AuctionStatus.LIVE
      ) {
        valuesToUpdate = {
          conversionRate: values.conversionRate,
          unhcrServicesFee: values.unhcrServicesFee,
          nonWfpLots: values.nonWfpLots,
          // auctioneerInvoice: values.auctioneerInvoice,
          ...(values.auctionImage.name && {
            auctionImage: values.auctionImage,
          }),
        };
      }

      helpers.setSubmitting(true);
      await onSubmit(valuesToUpdate, helpers);
      helpers.setSubmitting(false);
    },
    [onSubmit, initialValues.status]
  );

  useEffect(() => {
    createUrl(uploadedPhoto);
  }, [uploadedPhoto]);

  const handleChange = async (countryId: number) => {
    getCitiesByCountry(countryId);
  };

  const createUrl = (files: any): void => {
    let url: any = [];
    for (let file in files) {
      typeof files[file] === "object" &&
        url.push(URL.createObjectURL(files[file]));
    }
    setAuctionImage(url[0]);
  };

  const handlePhotoField = (photo: any, setFieldValue: any) => {
    setFieldValue("auctionImage", photo[0]);
    setUploadedPhoto(photo);
  };

  const isDisabled = useMemo(
    () =>
      initialValues.status === AuctionStatus.DONE ||
      initialValues.status === AuctionStatus.LIVE,
    [initialValues.status]
  );

  const formInitialValues: TUpdateAuctionFormValues = useMemo(() => {
    const deposits = Object.entries(initialValues.deposits ?? {}).reduce(
      (res, [asset, deposit]) => {
        const existingAssetGroupIndex = res.findIndex(
          (assetGroup) => assetGroup.deposit === deposit
        );

        if (existingAssetGroupIndex !== -1) {
          res[existingAssetGroupIndex].assets.push(asset as AssetFamily);
        } else {
          res.push({ assets: [asset as AssetFamily], deposit });
        }

        return res;
      },
      [] as {
        assets: Array<AssetFamily>;
        deposit: OptionalNotNull<number>;
      }[]
    );

    const auctioneerFees = Object.entries(
      initialValues.auctioneer_fees ?? {}
    ).reduce(
      (res, [asset, fee]) => {
        const existingAssetGroupIndex = res.findIndex(
          (assetGroup) => assetGroup.fee === fee
        );

        if (existingAssetGroupIndex !== -1) {
          res[existingAssetGroupIndex].assets.push(asset as AssetFamily);
        } else {
          res.push({ assets: [asset as AssetFamily], fee });
        }

        return res;
      },
      [] as {
        assets: Array<AssetFamily>;
        fee: OptionalNotNull<number>;
      }[]
    );

    return {
      ...INITIAL_VALUES,
      agency: initialValues.agency,
      city: initialValues.location.geoname_id.toString(),
      country: initialValues.location.country.geoname_id.toString(),
      currency: initialValues.currency.code,
      conversionRate: initialValues.conversion_rate ?? "",
      requiresOnlineDeposits: initialValues.requires_online_deposits ?? false,
      date: initialValues.auction_date,
      auctionEndDate: initialValues.auction_end_date,
      depositDate: initialValues.deposits_end_date ?? "",
      timezone: initialValues.timezone ?? "",
      auctionImage: initialValues.auction_image ?? "",
      isOnline: initialValues.is_online,
      deposits,
      auctioneerFees,
      gasStrategy: initialValues.gas_strategy,
      nonWfpLots: initialValues.unhcr_lot_count ?? undefined,
      unhcrServicesFee: initialValues.unhcr_services ?? undefined,
      auctioneerInvoice: initialValues.auctioneer_invoice ?? undefined,
      focalPoint: initialValues.co_focal_point
        ? {
            email: initialValues.co_focal_point.email,
            fullName: initialValues.co_focal_point.full_name,
            phone: initialValues.co_focal_point.phone,
          }
        : undefined,
      paymentAccount: initialValues.payment_account ?? "",
      paymentConditions: initialValues.payment_conditions ?? "",
      paymentConditionsFr: initialValues.payment_conditions_fr ?? "",
      paymentConditionsEs: initialValues.payment_conditions_es ?? "",
      paymentConditionsPt: initialValues.payment_conditions_pt ?? "",
      pickupDetails: initialValues.pickup_details ?? "",
      clearanceInstructions: initialValues.clearance_instructions ?? "",
      representative: initialValues.gas_team_representative
        ? {
            email: initialValues.gas_team_representative.email,
            fullName: initialValues.gas_team_representative.full_name,
            phone: initialValues.gas_team_representative.phone,
          }
        : undefined,
      sealedBid: initialValues.sealed,
      supervisor: initialValues.unhcr_supervisor
        ? {
            email: initialValues.unhcr_supervisor.email,
            fullName: initialValues.unhcr_supervisor.full_name,
            phone: initialValues.unhcr_supervisor.phone,
          }
        : undefined,
      sendEmailBidWasOutbid: initialValues.send_email_bid_was_outbid,
      sendEmailBidderWon: initialValues.send_email_bidder_won,
      emailLanguage: initialValues.email_language,
      enableBankTransfer: initialValues.enable_bank_transfer,
      enableOnlinePayment: initialValues.enable_online_payment,
      enableDepositBankTransfer: initialValues.enable_deposit_bank_transfer,
      enableDepositOnlinePayment: initialValues.enable_deposit_online_payment,
    };
  }, [initialValues]);

  return (
    <Formik<TUpdateAuctionFormValues>
      initialValues={formInitialValues}
      validationSchema={VALIDATION_SCHEMA(initialValues.status)}
      validate={validateForm}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        isValid,
        values,
        handleSubmit,
        handleChange: onChange,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Modal
            width="100%"
            primaryActions={<></>}
            actions={
              <>
                <Button
                  onClick={() => {
                    onClose?.();
                  }}
                  bordered
                >
                  {t<string>("auctionForm.cancel")}
                </Button>
                <Button
                  type="submit"
                  disabled={!isValid}
                  onClick={() => null}
                  loading={loading}
                >
                  {t<string>("auctionForm.update")}
                </Button>
              </>
            }
            onClose={() => null}
            fixed
          >
            <Label>{t("auctionForm.location")}</Label>
            <Row>
              <SelectField
                name="country"
                placeholder={t("auctionForm.country")}
                options={countries.map((country) => ({
                  label: country.name,
                  value: country.geoname_id,
                }))}
                onChange={(event) => {
                  setFieldValue("city", "");
                  onChange(event);
                  handleChange(Number(event.target.value));
                }}
                disabled={isDisabled}
              />
              <SelectField
                name="city"
                placeholder={t("auctionForm.city")}
                options={cities.map((city) => ({
                  label: getCityName(city),
                  value: city.geoname_id,
                }))}
                disabled={
                  !Boolean(values.country) || cities.length === 0 || isDisabled
                }
              />
            </Row>

            <Row>
              <DateField
                name="date"
                placeholder={t("auctionForm.date.placeholder")}
                label={t("auctionForm.date.label")}
                disabled={isDisabled}
              />
              {!initialValues.is_online ? (
                <SelectField
                  name="currency"
                  placeholder={t("auctionForm.currency.placeholder")}
                  label={t("auctionForm.currency.label")}
                  options={currencies.map((currency) => ({
                    label: currency.code,
                    value: currency.code,
                  }))}
                  onChange={(event) => {
                    onChange(event);
                    const currency = event.target.value;
                    if (currency === "USD") {
                      setConversionRateDisabled(true);
                      setFieldValue("conversionRate", 1);
                    } else {
                      setConversionRateDisabled(false);
                      setFieldValue("conversionRate", "");
                    }
                  }}
                  disabled={isDisabled}
                />
              ) : (
                <>
                  <DateField
                    name="auctionEndDate"
                    placeholder={t("auctionForm.auctionEndDate.placeholder")}
                    label={t("auctionForm.auctionEndDate.label")}
                  />
                  <SelectField
                    name="timezone"
                    placeholder="Select timezone"
                    label="Select timezone"
                    options={timezones.map((zone) => ({
                      label: zone.display_name,
                      value: zone.value,
                    }))}
                  />
                </>
              )}
            </Row>
            {initialValues.is_online && (
              <>
                <Row>
                  <SelectField
                    name="currency"
                    placeholder={t("auctionForm.currency.placeholder")}
                    label={t("auctionForm.currency.label")}
                    options={currencies.map((currency) => ({
                      label: currency.code,
                      value: currency.code,
                    }))}
                    onChange={(event) => {
                      onChange(event);
                      const currency = event.target.value;
                      if (currency === "USD") {
                        setConversionRateDisabled(true);
                        setFieldValue("conversionRate", 1);
                      } else {
                        setConversionRateDisabled(false);
                        setFieldValue("conversionRate", "");
                      }
                    }}
                  />
                </Row>
              </>
            )}
            <Row>
              <NumberField
                name="conversionRate"
                label={t("auctionForm.conversionRate.label")}
                placeholder={t("auctionForm.conversionRate.placeholder")}
                min={MIN_CONVERSION_RATE_VALUE}
                max={MAX_CONVERSION_RATE_VALUE}
                step="0.001"
                disabled={conversionRateDisabled}
              />
            </Row>
            {initialValues.is_online && (
              <>
                <Row>
                  <SwitchContainer>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "20px",
                      }}
                    >
                      <Label className="mr-6">
                        {t("auctionForm.depositToggle")}
                      </Label>
                    </div>
                    <div style={{ alignItems: "end" }}>
                      <SwitchField
                        name="requiresOnlineDeposits"
                        disabled={
                          initialValues.status === AuctionStatus.DONE ||
                          initialValues.status === AuctionStatus.LIVE
                        }
                      />
                    </div>
                  </SwitchContainer>
                </Row>
                {values.requiresOnlineDeposits && (
                  <>
                    <Row>
                      <div>
                        <DepositByAssetField />
                      </div>
                    </Row>
                    <Row>
                      <SwitchContainer>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "20px",
                          }}
                        >
                          <Label className="mr-6">
                            {t("auctionForm.enableDepositBankTransfer")}
                          </Label>
                        </div>
                        <div style={{ alignItems: "end" }}>
                          <SwitchField name="enableDepositBankTransfer" />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingRight: "20px",
                            paddingLeft: "26px",
                          }}
                        >
                          <Label className="mr-6">
                            {t("auctionForm.enableDepositOnlinePayment")}
                          </Label>
                        </div>
                        <div style={{ alignItems: "end" }}>
                          <SwitchField name="enableDepositOnlinePayment" />
                        </div>
                      </SwitchContainer>
                    </Row>
                    <Row>
                      <DateField
                        name="depositDate"
                        disabled={!values.deposits.length}
                        placeholder={t("auctionForm.depositDate.placeholder")}
                        label={t("auctionForm.depositDate.label")}
                      />
                    </Row>
                  </>
                )}
                <Label>{t("auctionForm.gasStrategy")}</Label>
                <Row>
                  <SelectField
                    name="gasStrategy"
                    placeholder="Default"
                    options={gasStrategies.map((strategy) => ({
                      label: strategy.name,
                      value: strategy.id,
                    }))}
                  />
                </Row>
                <Row>
                  <SwitchContainer>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "20px",
                      }}
                    >
                      <Label className="mr-6">
                        {t("auctionForm.emailNotification.outbid")}
                      </Label>
                      <div id="outbid-tooltip">
                        <Icon.InfoCircle />
                      </div>
                    </div>
                    <div style={{ alignItems: "end" }}>
                      <SwitchField name="sendEmailBidWasOutbid" />
                    </div>
                  </SwitchContainer>
                  <Tooltip anchorId="outbid-tooltip">
                    <TooltipContent>
                      {t("auctionForm.emailNotificationTooltip.outbid")}
                    </TooltipContent>
                  </Tooltip>
                </Row>
                <Row>
                  <SwitchContainer>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "20px",
                      }}
                    >
                      <Label className="mr-6">
                        {t("auctionForm.emailNotification.winner")}
                      </Label>
                      <div id="winner-tooltip">
                        <Icon.InfoCircle />
                      </div>
                    </div>
                    <div style={{ alignItems: "end" }}>
                      <SwitchField name="sendEmailBidderWon" />
                    </div>
                  </SwitchContainer>
                  <Tooltip anchorId="winner-tooltip">
                    <TooltipContent>
                      {t("auctionForm.emailNotificationTooltip.winner")}
                    </TooltipContent>
                  </Tooltip>
                </Row>
                <Row>
                  <SelectField
                    name="emailLanguage"
                    placeholder={t("auctionForm.emailLanguage")}
                    options={emailLanguages.map((language) => ({
                      label: language.name,
                      value: language.code,
                    }))}
                  />
                </Row>
                <Row>
                  <SwitchContainer>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "20px",
                      }}
                    >
                      <Label className="mr-6">
                        {t("auctionForm.enableBankTransfer")}
                      </Label>
                    </div>
                    <div style={{ alignItems: "end" }}>
                      <SwitchField name="enableBankTransfer" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "20px",
                        paddingLeft: "26px",
                      }}
                    >
                      <Label className="mr-6">
                        {t("auctionForm.enableOnlinePayment")}
                      </Label>
                    </div>
                    <div style={{ alignItems: "end" }}>
                      <SwitchField name="enableOnlinePayment" />
                    </div>
                  </SwitchContainer>
                </Row>
              </>
            )}
            <Row>
              <RowWithLabel>
                <LabelBox>
                  <Label className="mr-6">
                    {t("auctionForm.paymentAccount")}
                  </Label>
                  <div id="payment-account-tooltip">
                    <Icon.InfoCircle />
                  </div>
                </LabelBox>
                <TextAreaField
                  name="paymentAccount"
                  placeholder={t("auctionForm.paymentAccount")}
                  disabled={isDisabled}
                />
              </RowWithLabel>
              <Tooltip anchorId="payment-account-tooltip">
                <TooltipContent>
                  {t("auctionForm.paymentAccountTooltip")}
                </TooltipContent>
              </Tooltip>
            </Row>
            <Row>
              <RowWithLabel>
                <LabelBox style={{ paddingBottom: "6px" }}>
                  <Label className="mr-6">
                    {t("auctionForm.paymentConditions")}
                  </Label>
                  <div id="payment-conditions-tooltip">
                    <Icon.InfoCircle />
                  </div>
                </LabelBox>
                <Tabs
                  onTabClick={(index) => {
                    setActiveTabIndex(index);
                  }}
                  headers={["English", "French", "Portuguese", "Spanish"]}
                >
                  <TextAreaField
                    name="paymentConditions"
                    placeholder={t("auctionForm.paymentConditions")}
                    disabled={isDisabled}
                  />
                  <TextAreaField
                    name="paymentConditionsFr"
                    placeholder={t("auctionForm.paymentConditions")}
                    disabled={isDisabled}
                  />
                  <TextAreaField
                    name="paymentConditionsPt"
                    placeholder={t("auctionForm.paymentConditions")}
                    disabled={isDisabled}
                  />
                  <TextAreaField
                    name="paymentConditionsEs"
                    placeholder={t("auctionForm.paymentConditions")}
                    disabled={isDisabled}
                  />
                </Tabs>
              </RowWithLabel>
              <Tooltip anchorId="payment-conditions-tooltip">
                <TooltipContent>
                  {t("auctionForm.paymentConditionsTooltip")}
                </TooltipContent>
              </Tooltip>
            </Row>
            {initialValues.is_online && (
              <>
                <Row>
                  <RowWithLabel>
                    <LabelBox>
                      <Label className="mr-6">
                        {t("auctionForm.pickupDetails")}
                      </Label>
                      <div id="pickup-details-tooltip">
                        <Icon.InfoCircle />
                      </div>
                    </LabelBox>
                    <TextAreaField name="pickupDetails" />
                  </RowWithLabel>
                  <Tooltip anchorId="pickup-details-tooltip">
                    <TooltipContent>
                      {t("auctionForm.pickupDetailsTooltip")}
                    </TooltipContent>
                  </Tooltip>
                </Row>
                <Row>
                  <RowWithLabel>
                    <LabelBox>
                      <Label className="mr-6">
                        {t("auctionForm.clearanceInstructions")}
                      </Label>
                      <div id="clearance-instructions-tooltip">
                        <Icon.InfoCircle />
                      </div>
                    </LabelBox>
                    <TextAreaField name="clearanceInstructions" />
                  </RowWithLabel>
                  <Tooltip anchorId="clearance-instructions-tooltip">
                    <TooltipContent>
                      {t("auctionForm.clearanceInstructionsTooltip")}
                    </TooltipContent>
                  </Tooltip>
                </Row>
              </>
            )}
            {!initialValues.is_online && (
              <>
                <AuctioneerFeeByAssetField disabled={isDisabled} />
                <DepositByAssetField />
                <Label>{t("auctionForm.gasStrategy")}</Label>
                <Row>
                  <SelectField
                    name="gasStrategy"
                    placeholder="Default"
                    options={gasStrategies.map((strategy) => ({
                      label: strategy.name,
                      value: strategy.id,
                    }))}
                  />
                </Row>
                <Row>
                  <ButtonGroupField
                    name="agency"
                    label={t("auctionForm.agency")}
                    options={[
                      { label: "UNHCR", value: Agency.UNHCR },
                      { label: "WFP", value: Agency.WFP },
                    ]}
                    onChange={(agency: Agency) => {
                      if (agency === Agency.WFP) {
                        setFieldValue("supervisor", INITIAL_VALUES.supervisor);
                        setFieldValue("nonWfpLots", INITIAL_VALUES.nonWfpLots);
                        setFieldValue(
                          "unhcrServicesFee",
                          INITIAL_VALUES.unhcrServicesFee
                        );
                      }
                    }}
                    disabled={isDisabled}
                  />
                  <SwitchField
                    name="sealedBid"
                    label={
                      values.sealedBid
                        ? t("auctionForm.sealedBid")
                        : t("auctionForm.public")
                    }
                    disabled={isDisabled}
                  />
                </Row>
              </>
            )}

            {values.agency === Agency.UNHCR && !initialValues.is_online && (
              <>
                <Row>
                  <NumberField
                    name="nonWfpLots"
                    label={t("auctionForm.nonWfpLots.label")}
                    placeholder={t("auctionForm.nonWfpLots.placeholder")}
                    step={1}
                    min={MIN_NON_WFP_LOTS_VALUE}
                  />
                  <NumberField
                    name="unhcrServicesFee"
                    label={t("auctionForm.unhcrServicesFee.label")}
                    placeholder={t("auctionForm.unhcrServicesFee.placeholder")}
                    step={0.01}
                    min={MIN_UNHCR_SERVICES_VALUE}
                  />
                </Row>
                <Label>{t("auctionForm.supervisor")}</Label>
                <Row>
                  <TextField
                    name="supervisor.fullName"
                    placeholder={t("auctionForm.fullName")}
                    disabled={isDisabled}
                  />
                  <TextField
                    name="supervisor.email"
                    placeholder={t("auctionForm.email")}
                    disabled={isDisabled}
                  />
                </Row>
                <Row>
                  <TextField
                    name="supervisor.phone"
                    placeholder={t("auctionForm.phone")}
                    disabled={isDisabled}
                  />
                </Row>
              </>
            )}

            {values.agency === Agency.WFP && !values.isOnline && (
              <Row>
                <NumberField
                  name="auctioneerInvoice"
                  label={t("auctionForm.auctioneerInvoice.label")}
                  placeholder={t("auctionForm.auctioneerInvoice.label")}
                  step={0.01}
                  min={MIN_UNHCR_SERVICES_VALUE}
                />
              </Row>
            )}

            <Label>{t("auctionForm.focalPoint")}</Label>
            <Row>
              <TextField
                name="focalPoint.fullName"
                placeholder={t("auctionForm.fullName")}
                disabled={isDisabled}
              />
              <TextField
                name="focalPoint.email"
                placeholder={t("auctionForm.email")}
                disabled={isDisabled}
              />
            </Row>
            <Row>
              <TextField
                name="focalPoint.phone"
                placeholder={t("auctionForm.phone")}
                disabled={isDisabled}
              />
            </Row>

            <Label>{t("auctionForm.representative")}</Label>
            <Row>
              <TextField
                name="representative.fullName"
                placeholder={t("auctionForm.fullName")}
                disabled={isDisabled}
              />
              <TextField
                name="representative.email"
                placeholder={t("auctionForm.email")}
                disabled={isDisabled}
              />
            </Row>
            <Row>
              <TextField
                name="representative.phone"
                placeholder={t("auctionForm.phone")}
                disabled={isDisabled}
              />
            </Row>
            {initialValues.is_online && (
              <Row>
                <FileField
                  name="auctionImage"
                  accept="image/*"
                  label={t("lotForm.photo.label")}
                  placeholder={t("lotForm.photo.placeholder")}
                  onChange={(event) => {
                    handlePhotoField(event.currentTarget.files, setFieldValue);
                  }}
                />
              </Row>
            )}
            {initialValues.is_online &&
              values.auctionImage &&
              (imageIsLoading ? (
                <Loader size="small" />
              ) : (
                <ImageContainer>
                  <img
                    src={auctionImage}
                    alt="auction logo"
                    style={{ maxWidth: "300px" }}
                  />
                </ImageContainer>
              ))}
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
