import { Agency, AssetFamily, OptionalNotNull } from "@utils";

interface PersonalData {
  fullName: string;
  email: string;
  phone: string;
}

export const INITIAL_VALUES = {
  isOnline: false,
  auctionImage: "",
  country: "",
  city: "",
  date: "",
  auctionEndDate: "",
  depositDate: "",
  timezone: "",
  currency: "",
  paymentAccount: "",
  paymentConditions:
    "The seller certifies to the purchaser that he is the owner of the item and has the authority to " +
    "sell it and transfer his property to the purchaser. \n" +
    "- In case of a banking transfer, the sale will only be valid and complete after receipt of proof of payment (copy " +
    "of bank transfer) of the total amount indicated above. \n" +
    "- A period of 5 (five) days is granted to the purchaser for the completion of customs formalities. After this period, " +
    "the purchaser will pay the costs of storage until the effective removal of the item.\n" +
    "- The seller certifies having received from the purchaser the payment requested in order to proceed with the release of the item.",
  paymentConditionsFr:
    "- Le Vendeur certifie à l’Acheteur être le propriétaire de l’article et avoir l’autorité de le vendre et de transférer " +
    "sa propriété à l’Acheteur.\n" +
    "- En cas de virement, la vente ne sera valide et complète qu’après réception de la preuve du paiement (copie du virement " +
    "bancaire) du montant total indiqué ci-dessus.\n" +
    "- Un délai de 5 (cinq) jours est accordé à l’Acheteur pour l’accomplissement des formalités douanières. Passé ce délai," +
    " l’Acheteur paiera les frais de gardiennage jusqu’à l’enlèvement effectif de l’article remporté.\n" +
    "- Le Vendeur certifie avoir reçu de l’Acheteur le paiement demandé afin de procéder à la cession de l’article.",
  paymentConditionsEs:
    "- El vendedor certifica al comprador que es el propietario del artículo y tiene la autoridad para venderlo y " +
    "transferir su propiedad al comprador.\n" +
    "- En el caso de una transferencia bancaria, la venta solo será válida y completa después de recibir el comprobante " +
    "de pago (copia de la transferencia bancaria) por el monto total indicado anteriormente.\n" +
    "- Se concede un plazo de 5 (cinco) días al comprador para reailzar los trámites aduaneros. Transcurrido este plazo, " +
    "el comprador correrá con los gastos de almacenamiento hasta la retirada efectiva del artículo.\n" +
    "- El vendedor certifica haber recibido del comprador el pago solicitado para proceder a la entrega del artículo.",
  paymentConditionsPt:
    "- O vendedor certifica ao comprador que ele é o proprietário do artigo e tem autoridade para vendê-lo e transferir " +
    "sua propriedade ao comprador.\n" +
    "- No caso de transferência bancária, a venda só será válida e completa após recepção do comprovativo de pagamento " +
    "(cópia da transferência bancária) do valor total acima indicado.\n" +
    "- É concedido ao comprador um prazo de 5 (cinco) dias para o cumprimento das formalidades aduaneiras. Após este " +
    "período, o comprador arcará com os custos de armazenamento até a efetiva retirada do item.\n" +
    "- O vendedor certifica ter recebido do comprador o pagamento solicitado para proceder à liberação do item.",
  pickupDetails: "",
  clearanceInstructions: "",
  conversionRate: "",
  requiresOnlineDeposits: false,
  auctioneerFees: [] as {
    assets: Array<AssetFamily>;
    fee: OptionalNotNull<number>;
  }[],
  deposits: [] as {
    assets: Array<AssetFamily>;
    deposit: OptionalNotNull<number>;
  }[],
  gasStrategy: null,
  agency: Agency.UNHCR,
  sealedBid: false,
  supervisor: undefined as OptionalNotNull<PersonalData>,
  focalPoint: undefined as OptionalNotNull<PersonalData>,
  representative: undefined as OptionalNotNull<PersonalData>,
  unhcrServicesFee: undefined as OptionalNotNull<number>,
  nonWfpLots: undefined as OptionalNotNull<number>,
  auctioneerInvoice: undefined as OptionalNotNull<number>,
  sendEmailBidWasOutbid: false,
  sendEmailBidderWon: false,
  emailLanguage: "",
  enableBankTransfer: true,
  enableOnlinePayment: false,
  enableDepositBankTransfer: true,
  enableDepositOnlinePayment: false,
};
