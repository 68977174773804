import { array, boolean, date, number, object, string } from "yup";

import { ASSETS_FAMILY } from "@utils";
import {
  MAX_CONVERSION_RATE_VALUE,
  MAX_DEPOSIT_VALUE,
  MAX_FEE_VALUE,
  MIN_DEPOSIT_VALUE,
  MIN_FEE_VALUE,
  MIN_NON_WFP_LOTS_VALUE,
  MIN_UNHCR_SERVICES_VALUE,
} from "./consts";
import { TCreateAuctionFormValues } from "./CreateAuctionForm";

const phoneRegex = /^\+\d{12}$/g;

const personalDataSchema = object({
  fullName: string(),
  email: string().email("errors.email.invalid"),
  // phone: string().matches(phoneRegex, "errors.phone.format"),
});

export const VALIDATION_SCHEMA = object({
  isOnline: boolean(),
  country: number().required("errors.country.required"),
  city: number().required("errors.city.required"),
  date: date()
    .when("isOnline", {
      is: true,
      then: date().min(new Date(), "errors.date.later"),
    })
    .required("errors.date.required"),
  auctionEndDate: date()
    .min(new Date(), "errors.date.later")
    .when("isOnline", {
      is: true,
      then: date().required("errors.date.required"),
    }),
  currency: string().required("errors.currency.required"),
  conversionRate: number()
    .positive("auctionForm.errors.conversionRate.min")
    .max(MAX_CONVERSION_RATE_VALUE, "auctionForm.errors.conversionRate.max")
    .test(
      "maxDigitsAfterDecimal",
      "auctionForm.errors.conversionRate.decimals",
      (number) =>
        number ? /^\d+(\.\d{1,3})?$/.test(number?.toString() ?? "") : true
    ),
  auctioneerFees: array()
    .required("auctionForm.errors.fee.required")
    .of(
      object({
        assets: array().of(string()),
        fee: number()
          .min(MIN_FEE_VALUE, "auctionForm.errors.fee.min")
          .max(MAX_FEE_VALUE, "auctionForm.errors.fee.max"),
      })
    ),
  deposits: array()
    .required("auctionForm.errors.deposit.required")
    .of(
      object({
        assets: array().of(string()),
        deposit: number()
          .min(MIN_DEPOSIT_VALUE, "auctionForm.errors.deposit.min")
          .max(MAX_DEPOSIT_VALUE, "auctionForm.errors.deposit.max"),
      })
    ),
  unhcrServicesFee: number()
    .min(MIN_UNHCR_SERVICES_VALUE, "auctionForm.errors.unhcrServicesFee.min")
    .test(
      "maxDigitsAfterDecimal",
      "auctionForm.errors.unhcrServicesFee.decimals",
      (number) =>
        number ? /^\d+(\.\d{1,2})?$/.test(number?.toString() ?? "") : true
    ),
  nonWfpLots: number()
    .positive("auctionForm.errors.nonWfpLots.min")
    .min(MIN_NON_WFP_LOTS_VALUE, "auctionForm.errors.nonWfpLots.min"),
  auctioneerInvoice: number()
    .min(MIN_UNHCR_SERVICES_VALUE, "auctionForm.errors.auctioneerInvoice.min")
    .test(
      "maxDigitsAfterDecimal",
      "auctionForm.errors.auctioneerInvoice.decimals",
      (number) =>
        number ? /^\d+(\.\d{1,2})?$/.test(number?.toString() ?? "") : true
    ),
  supervisor: personalDataSchema,
  focalPoint: personalDataSchema,
  representative: personalDataSchema,
  emailLanguage: string().when("isOnline", {
    is: true,
    then: string().required("auctionForm.errors.emailLanguage.required"),
  }),
});

export const validateForm = (values: TCreateAuctionFormValues) => {
  const errors: any = {};

  if (!values.isOnline) {
    if (
      values.auctioneerFees.flatMap((auctionGroup) => auctionGroup.assets)
        .length !== ASSETS_FAMILY.length
    ) {
      errors.auctioneerFees = "auctionForm.errors.fee.invalid";
    }
    if (
      values.deposits.flatMap((depositGroup) => depositGroup.assets).length !==
      ASSETS_FAMILY.length
    ) {
      errors.deposit = "auctionForm.errors.deposit.invalid";
    }
  }

  if (values.isOnline && values.requiresOnlineDeposits) {
    if (
      values.deposits.flatMap((depositGroup) => depositGroup.assets).length !==
      ASSETS_FAMILY.length
    ) {
      errors.deposit = "auctionForm.errors.deposit.invalid";
    }
  }

  if (values.supervisor) {
    const { fullName, email, phone } = values.supervisor;
    const fieldsFilled = [fullName, email, phone].filter(Boolean).length;
    if (fieldsFilled > 0 && fieldsFilled < 3) {
      if (!fullName) {
        errors.supervisor = errors.supervisor || {};
        errors.supervisor.fullName =
          "Full Name is required if Email or Phone is provided";
      }
      if (!email) {
        errors.supervisor = errors.supervisor || {};
        errors.supervisor.email =
          "Email is required if Full Name or Phone is provided";
      }
      if (!phone) {
        errors.supervisor = errors.supervisor || {};
        errors.supervisor.phone =
          "Phone is required if Full Name or Email is provided";
      }
    }
  }
  if (values.focalPoint) {
    const { fullName, email, phone } = values.focalPoint;
    const fieldsFilled = [fullName, email, phone].filter(Boolean).length;
    if (fieldsFilled > 0 && fieldsFilled < 3) {
      if (!fullName) {
        errors.focalPoint = errors.focalPoint || {};
        errors.focalPoint.fullName =
          "Full Name is required if Email or Phone is provided";
      }
      if (!email) {
        errors.focalPoint = errors.focalPoint || {};
        errors.focalPoint.email =
          "Email is required if Full Name or Phone is provided";
      }
      if (!phone) {
        errors.focalPoint = errors.focalPoint || {};
        errors.focalPoint.phone =
          "Phone is required if Full Name or Email is provided";
      }
    }
  }
  if (values.representative) {
    const { fullName, email, phone } = values.representative;
    const fieldsFilled = [fullName, email, phone].filter(Boolean).length;
    if (fieldsFilled > 0 && fieldsFilled < 3) {
      if (!fullName) {
        errors.representative = errors.representative || {};
        errors.representative.fullName =
          "Full Name is required if Email or Phone is provided";
      }
      if (!email) {
        errors.representative = errors.representative || {};
        errors.representative.email =
          "Email is required if Full Name or Phone is provided";
      }
      if (!phone) {
        errors.representative = errors.representative || {};
        errors.representative.phone =
          "Phone is required if Full Name or Email is provided";
      }
    }
  }

  return errors;
};
