import Axios from "axios";

export async function currencies() {
  const response = await Axios.get<{ code: string }[]>("/api/currencies/");
  return response.data;
}

export async function getTimezones() {
  const response = await Axios.get("/api/timezones/");
  return response.data;
}

export async function getGasStrategies() {
  const response = await Axios.get("/api/gas-strategies/");
  return response.data;
}
